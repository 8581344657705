import React, { useContext, useEffect, useState, useRef } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import Container from '@objects/container'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import { useIntl, FormattedMessage } from 'react-intl'

import clsx from 'clsx'
import { Link } from 'gatsby'
import { useLocation } from '@reach/router'

import { getUnixTime } from 'date-fns'

import api from '@api'
import {
  ANSWER_SUBMIT_SUCCESS,
  COMMENT_SUBMIT_SUCCESS,
  eventBus,
} from '@services/eventBus'
import DialogContext from '@providers/dialogProvider'
import ToggleComponentContext from '@providers/toggleComponentProvider'

import PostsFilter from '@components/posts/filter'
import PostCard from '@components/postCard'
// TODO: use input instead of button
// import PostInputField from '@objects/formFields/postInput'
import CreateContentButton from '@objects/button/createContentButton'
import Card from '@objects/card'
import Headline from '@objects/headline'
import Button from '@objects/button'
import Select from '@objects/formFields/select'
import PostSnackbar from '@components/postSnackbar'

const useStyles = makeStyles((theme) => ({
  pageHeader: {
    marginTop: theme.spacing(8),
  },
  backButton: {
    flexDirection: 'row-reverse',
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(12),
    },
    '& svg': {
      margin: theme.spacing(0, 2, 0, 0),
    },
  },
  answersMeta: {
    display: 'flex',
    flexDirection: 'column-reverse',
    '& > *': {
      marginBottom: theme.spacing(5),
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(5),
    },
  },
  metaCount: {
    fontWeight: 700,
    lineHeight: '40px',
  },
  select: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      minWidth: '300px',
    },
  },
  main: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(5),
    },
  },
  sidebar: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(10),
    },
  },
  sidebarLink: {
    lineHeight: 1.375,
    marginBottom: theme.spacing(7),
    '& a': {
      ...theme.typography.link,
    },
    '& span': {
      ...theme.typography.teaser,
    },
  },
  box: {
    background: theme.palette.background.default,
    borderRadius: theme.spacing(4, 0, 0, 4),
    padding: theme.spacing(6, 6, 8, 6),
    marginBottom: theme.spacing(10),
    marginRight: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(6, 8, 8, 8),
    },
  },
  topicFilter: {},
  topicSeparator: {
    border: `1px solid ${theme.palette.grey.light}`,
    margin: theme.spacing(7, 0, 7, 0),
  },
  sideBarHeadline: {
    ...theme.typography.h4,
    marginBottom: theme.spacing(5),
  },
  answerCard: {
    marginLeft: theme.spacing(-4),
    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
    },
  },
}))

export const frontmatter = {
  pageBackground: 'grey',
  header: {
    cta: 'createAnswer',
  },
  breadcrumbs: [
    {
      label: 'footer.dialog',
      link: 'dialog.path',
    },
    {
      label: 'navigation.dialog.forum',
      link: 'forum.path',
    },
  ],
}

const orderByOpts = (intl) => {
  return [
    {
      // value: 'Newest',
      value: 1,
      label: intl.formatMessage({ id: 'dialogue.order.answer.Newest' }),
    },
    {
      // value: 'MostPopular',
      value: 2,
      label: intl.formatMessage({ id: 'dialogue.order.answer.MostPopular' }),
    },
    {
      // value: 'Oldest',
      value: 3,
      label: intl.formatMessage({ id: 'dialogue.order.answer.Oldest' }),
    },
    {
      // value: 'MostControversial',
      value: 4,
      label: intl.formatMessage({
        id: 'dialogue.order.answer.MostControversial',
      }),
    },
  ]
}

function PostPage({ articleid, pageContext, ...props }) {
  const location = useLocation()
  const classes = useStyles()
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { OverlayIsActive } = useContext(ToggleComponentContext)
  const { updatePostData } = useContext(DialogContext)
  const postRequest = api().getPost

  const [pageState, setPageState] = useState({
    post: null,
    relatedPosts: [],
    otherPosts: [],
    answers: [],
    topics: [],
    filteredAnswers: [],
  })
  const [loading, setLoading] = useState(true)
  const pageStateAnswersRef = useRef(pageState)

  const filterAnswers = (val) => {
    let filtered = [...pageState.answers]
    switch (val) {
      case 1:
        // newest
        filtered = filtered.sort((a, b) => {
          return getUnixTime(new Date(a.timestamp)) >
            getUnixTime(new Date(b.timestamp))
            ? -1
            : 1
        })
        break
      case 2:
        // likes
        filtered = filtered.sort((a, b) => {
          return a.numberOfLikes < b.numberOfLikes ? 1 : -1
        })
        break
      case 3:
        // oldest
        filtered = filtered.sort((a, b) => {
          return getUnixTime(new Date(a.timestamp)) >
            getUnixTime(new Date(b.timestamp))
            ? 1
            : -1
        })
        break
      case 4:
        // discussed
        filtered = filtered.sort((a, b) => {
          return a.comments.length < b.comments.length ? 1 : -1
        })
        break
      default:
        break
    }

    return filtered
  }

  const updateOpt = (opt, action) => {
    setPageState((pageState) => ({
      ...pageState,
      filteredAnswers: filterAnswers(opt.value).sort((a, b) =>
        a.isExpertAnswer ? -1 : 1
      ),
    }))

    return opt
  }

  const scrollToId = (idSelector, controlId) => {
    setTimeout(() => {
      if (controlId) {
        // eslint-disable-next-line no-unused-expressions
        document.getElementById(controlId)?.click()
      }
      const element = document.getElementById(idSelector)
      window.scrollTo({
        behavior: element ? 'smooth' : 'auto',
        top: element
          ? element.getBoundingClientRect().top + window.pageYOffset - 32
          : 0,
      })
    }, 500)
  }

  const getPost = (opts = {}) => {
    const { forceReload = false } = opts
    if (!pageState.post || forceReload) {
      return postRequest({ id: articleid })
        .then((response) => {
          setPageState((pageState) => ({
            ...pageState,
            post: response.data.article,
            relatedPosts: response.data.relatedArticles,
            otherPosts: response.data.otherArticles,
            answers: response.data.article.answers,
            topics: response.data.topics,
            filteredAnswers: response.data.article.answers.sort((a, b) =>
              a.isExpertAnswer ? -1 : 1
            ),
          }))
          updatePostData({
            isDeleted: response.data.article.isDeleted,
            deleteType: response.data.article.deleteType,
            isSingle: true,
            articleId: response.data.article.id,
            articleTitle: response.data.article.title,
          })
          setLoading((loading) => false)
          return response
        })
        .catch((err) => {
          //todo return/redirect 404
          console.error('get single post error:', err.message)
          setLoading((loading) => false)
        })
    }
  }

  useEffect(() => {
    const opts = {
      dialogueId: query?.dialogueid ?? 0,
      answerId: query?.answerid ?? 0,
      commentId: query?.commentid ?? 0,
      forceReload: true,
    }
    setLoading((loading) => true)

    getPost(opts)
      .then((response) => {
        // after first load
        if (opts.dialogueId) {
          //scroll to opt.dialogue
          scrollToId(`postcard-dialogue-full-${opts.dialogueId}`)
        }
        if (opts.commentId && opts.answerId) {
          //scroll to opt.answer
          scrollToId(
            `postcard-comment-${opts.commentId}`,
            `answer-${opts.answerId}-control`
          )
        } else if (opts.answerId) {
          //scroll to opt.comment
          scrollToId(
            `postcard-answer-${opts.answerId}`,
            `answer-${opts.answerId}-control`
          )
        }
      })
      .catch((e) => console.error(e))
    eventBus.on(ANSWER_SUBMIT_SUCCESS, onAnswerSuccessCallback)
    eventBus.on(COMMENT_SUBMIT_SUCCESS, onCommentSuccessCallback)
    return () => {
      eventBus.remove(ANSWER_SUBMIT_SUCCESS, onAnswerSuccessCallback)
      eventBus.remove(COMMENT_SUBMIT_SUCCESS, onCommentSuccessCallback)
    }
  }, [location])

  useEffect(() => {
    return () => {
      // reset post data on unmount
      updatePostData({
        isDeleted: false,
        isSingle: false,
      })
    }
  }, [])

  const onAnswerSuccessCallback = (data) => {
    setLoading((loading) => true)
    getPost({ forceReload: true, answerId: data?.answerId })
      .then(() => {
        scrollToId(
          `postcard-comment-${data?.commentId}`,
          `answer-${data?.answerId}-control`
        )
      })
      .catch((e) => console.error(e))
  }
  const onCommentSuccessCallback = (data) => {
    setLoading((loading) => true)
    getPost({
      forceReload: true,
      commentId: data?.commentId,
      answerId: data?.answerId,
    })
      .then(() => {
        scrollToId(
          `postcard-comment-${data?.commentId}`,
          `answer-${data?.answerId}-control`
        )
      })
      .catch((e) => console.error(e))
  }

  const snackBarGetUpdate = () => {
    return postRequest({ id: articleid })
      .then((response) => {
        const answerCount = response.data.article.answers.length

        if (answerCount > pageStateAnswersRef.current) {
          return answerCount - pageStateAnswersRef
        } else {
          return 0
        }
      })
      .catch((err) => {
        console.error('get single post update error:', err.message)
      })
  }

  const snackBarCallback = () => {
    setLoading((loading) => true)
    getPost({
      forceReload: true,
      commentId: 0,
      answerId: 0,
    })
      .then(() => {
        scrollToId('answersMeta')
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    pageStateAnswersRef.current = pageState.answers.length
  }, [pageState])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <Container className={classes.root}>
        <Grid container justify={'center'}>
          <Grid className={classes.main} item xs={12} md={10} lg={12}>
            <div className={classes.pageHeader}>
              <Button
                className={classes.backButton}
                color="outline"
                type="secondary"
                icon="ArrowLeft"
                to={intl.formatMessage({ id: 'forum.path' })}
              >
                <FormattedMessage id="dialogue.back.overview" />
              </Button>
            </div>
          </Grid>
          <Grid className={classes.main} item xs={12} md={10} lg={8}>
            {loading && (
              <PostCard
                postData={{}}
                loading={loading}
                type={'dialogue-full'}
              />
            )}
            {pageState.post && (
              <PostCard
                postData={{
                  likeCount: pageState.post.numberOfLikes,
                  userName: pageState.post.userName,
                  userColor: pageState.post.userColor,
                  timestamp: pageState.post.timestamp,
                  isDeleted: pageState.post.isDeleted,
                  deleteType: pageState.post.deleteType,
                  topics: pageState.post.topics,
                  title: pageState.post.title,
                  text: pageState.post.text,
                  id: pageState.post.id,
                }}
                type={'dialogue-full'}
                interactive={true}
              />
            )}

            <div className={classes.answersMeta} id="answersMeta">
              <div className={classes.metaCount}>
                <FormattedMessage
                  id={'dialogue.allAnswersCount'}
                  values={{ count: pageState.answers.length }}
                />
              </div>
              <div className={classes.select}>
                <Select
                  settings={{
                    defaultValue: orderByOpts(intl)[0],
                    options: orderByOpts(intl),
                    onChange: updateOpt,
                  }}
                />
              </div>
            </div>

            {pageState.filteredAnswers &&
              pageState.filteredAnswers.map((answer) => {
                return (
                  <PostCard
                    bar={answer.isExpertAnswer ? 'yellow' : 'grey'}
                    background={answer.isExpertAnswer ? 'dark' : 'default'}
                    postData={{
                      likeCount: answer.numberOfLikes,
                      replyCount: answer.comments.length,
                      userName: answer.userName,
                      userColor: answer.userColor,
                      timestamp: answer.timestamp,
                      isDeleted: answer.isDeleted,
                      deleteType: answer.deleteType,
                      topics: answer.topics,
                      title: answer.title,
                      text: answer.text,
                      isExpert: answer.isExpertAnswer,
                      isEdited: answer.isEdited,
                      editReason: answer.editReason,
                      lastEditedTimestamp: answer.lastEditedTimestamp,
                      id: answer.id,
                      comments: answer.comments,
                    }}
                    type={'answer'}
                    key={_.uniqueId('answer-')}
                    interactive={true}
                  />
                )
              })}
            {!pageState.post?.isDeleted && (
              <Card
                className={classes.answerCard}
                bar={'grey'}
                barOrientation={'right'}
              >
                <CreateContentButton
                  color={'outlineGrey'}
                  icon="Pen"
                  fullwidth
                  contentType="createAnswer"
                  articleId={parseInt(articleid)}
                  onSuccessCallback={onAnswerSuccessCallback}
                >
                  <FormattedMessage id={'dialogue.createAnswer'} />
                </CreateContentButton>
              </Card>
            )}
          </Grid>
          <Grid className={classes.sidebar} item xs={12} md={10} lg={4}>
            <div className={clsx(classes.box, classes.topicFilter)}>
              <Headline level={2} className={classes.sideBarHeadline}>
                <FormattedMessage id={'dialogue.post.topics'} />
              </Headline>
              {pageState.topics && (
                <PostsFilter topicsArray={pageState.topics} type={'sidebar'} />
              )}
            </div>
            <div className={clsx(classes.box, classes.topicFilter)}>
              {!!pageState.relatedPosts.length && (
                <div>
                  <Headline level={2} className={classes.sideBarHeadline}>
                    <FormattedMessage id={'dialogue.relatedPosts'} />
                  </Headline>
                  {pageState.relatedPosts.map((related) => {
                    return (
                      <div
                        className={classes.sidebarLink}
                        key={_.uniqueId('related-post-')}
                      >
                        <Link
                          data-track-content
                          data-tracking-id={intl.formatMessage(
                            { id: 'forum.path.single' },
                            { postId: related.id }
                          )}
                          to={intl.formatMessage(
                            { id: 'forum.path.single' },
                            { postId: related.id }
                          )}
                        >
                          {related.title}
                        </Link>
                        <br />
                        <span>
                          <FormattedMessage
                            id={'dialogue.allAnswersCount'}
                            values={{
                              count:
                                related.numberOfUserAnswers +
                                related.numberOfExpertAnswers,
                            }}
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
              {!!pageState.relatedPosts.length &&
                !!pageState.otherPosts.length && (
                  <hr className={classes.topicSeparator} />
                )}
              {!!pageState.otherPosts.length && (
                <div>
                  <Headline level={2} className={classes.sideBarHeadline}>
                    <FormattedMessage id={'dialogue.otherPosts'} />
                  </Headline>
                  {pageState.otherPosts.map((otherPost) => {
                    return (
                      <div
                        className={classes.sidebarLink}
                        key={_.uniqueId('other-post-')}
                      >
                        <Link
                          data-track-content
                          data-tracking-id={intl.formatMessage(
                            { id: 'forum.path.single' },
                            { postId: otherPost.id }
                          )}
                          to={intl.formatMessage(
                            { id: 'forum.path.single' },
                            { postId: otherPost.id }
                          )}
                        >
                          {otherPost.title}
                        </Link>
                        <br />
                        <span>
                          <FormattedMessage
                            id={'dialogue.allAnswersCount'}
                            values={{
                              count:
                                otherPost.numberOfUserAnswers +
                                otherPost.numberOfExpertAnswers,
                            }}
                          />
                        </span>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </Grid>
        </Grid>
        {!OverlayIsActive && (
          <PostSnackbar
            interval={120}
            getUpdate={snackBarGetUpdate}
            actionCallback={snackBarCallback}
            actionLabel={intl.formatMessage({
              id: 'snackbar.newanswers.reload',
            })}
            countLabelId={'snackbar.newanswers.count'}
          />
        )}
      </Container>
    </>
  )
}

PostPage.propTypes = {
  articleid: PropTypes.string,
  children: PropTypes.any,
  pageContext: PropTypes.any,
}

PostPage.defaultProps = {}

export default PostPage
